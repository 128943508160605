html,
body {
  margin: 0;
  padding: 0;
}

.video-player-wrap {
  height: 100vh;
  position: relative;
  width: 100vw;

  .change-video {
    color: blue;
    border: 1px solid #dcdada;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
  }

  video,
  iframe {
    // height: 100%;
    // position: absolute;
    // width: 100%;
  }
}

.file-upload-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f1f3f4;

  .drop-zone-container {
    width: 50%;
    margin: auto;
    height: 30vh;
    border: 1px dashed blue;
    display: flex;
    flex-direction: column;
    color: grey;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    p {
      margin: 0;
    }

    .help-text {
      font-size: 0.875rem;
    }
  }
}

.video-in-use-wrapper {
  display: flex !important;
  justify-content: space-evenly;
   .change-video {
    white-space: nowrap !important;

   }
  .react-player-wrapper > div {
    height: 100vh !important;
    width: 80vw !important;
  }
}